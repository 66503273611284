(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard
   * @description
   *
   */
  angular
    .module('dashboard', [
      'ui.router',
      'dashboard.index',
      'dashboard.profile',
      'dashboard.learning',
      'dashboard.discussions',
      'dashboard.sessions',
      'dashboard.schools',
      'dashboard.results',
      'dashboard.groups',
      'dashboard.students',
      'dashboard.reports',
      'dashboard.timeline',
      'dashboard.modules'
    ]);
}());
